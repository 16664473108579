import _ from "lodash"
import { pingTurnsWebrtc } from "@/common/webrtc/helpers"
import { mapGetters } from "vuex"
import WebrtcSenderWebengine from "@/common/webrtc/webengine/webrtc-sender-webengine"
import WebrtcReceiverWebengine from "@/common/webrtc/webengine/webrtc-receiver-webengine"

export default {
  data() {
    return {
      sender: null,
      receiver: null,
      generateLinkLoading: false,
    }
  },
  methods: {
    closeDialog() {
      let self = this
      self.$store.dispatch("webenginesResetData")
      self.$store.commit("RESET_LIST_MEMBERS_WS_CONN")
      this.stopLocalStream()
      this.stopPreviewAspxStream()
      this.$router.push("/engines/webengines").catch((err) => {})
    },

    getSignalServersList: function () {
      let self = this
      let getParams = {
        params: {
          type: "signal_server",
        },
      }
      return new Promise((resolve) => {
        self.$store.dispatch("signalServersList", getParams).then(function () {
          resolve()
        })
      })
    },

    getTurnServersList: function () {
      let self = this
      let getParams = {
        params: {
          type: "turn_server",
        },
      }

      return new Promise((resolve) => {
        self.$store
          .dispatch("turnServersCompanyGetLists", getParams)
          .then(function (response) {
            let turnServers = response.data.data.settings_multi || []
            let localTurns = _.unionBy(
              turnServers,
              self.modelWebengine.listLocalTurns,
              "_key"
            )
            self.$store.dispatch("saveListLocalTurns", localTurns)
            let remoteTurns = _.unionBy(
              turnServers,
              self.modelWebengine.listRemoteTurns,
              "_key"
            )
            self.$store
              .dispatch("saveListRemoteTurns", remoteTurns)
              .then(() => {
                if (self.editedIndex == -1) {
                  pingTurnsWebrtc(self.modelWebengine.listLocalTurns).then(
                    function (minPingItem) {
                      let saveTurnLocal = self.$store.dispatch(
                        "saveTurnLocal",
                        minPingItem._key
                      )
                      let saveTurnRemote = self.$store.dispatch(
                        "saveTurnRemote",
                        minPingItem._key
                      )
                      Promise.all([saveTurnLocal, saveTurnRemote]).then(
                        function () {
                          resolve()
                        }
                      )
                    }
                  )
                } else {
                  resolve()
                }
              })
          })
      })
    },

    getCompanyInfo: function () {
      return this.$store.dispatch("currentUserCompanyInfo")
    },

    getItemData() {
      return this.editedIndex !== -1 && !this.link_hash
        ? this.$store.dispatch("webenginesGetSingle", {
            slug: this.editedIndex,
          })
        : Promise.resolve()
    },

    initDialogData() {
      let self = this
      self.$store.dispatch("saveLoaderAction", true)
      let promiseArr = [
        self.getSignalServersList(),
        self.getTurnServersList(),
        self.getItemData(),
      ]
      if (!self.hasHashParam) {
        promiseArr.push(self.getCompanyInfo())
      }
      return Promise.all(promiseArr)
    },

    createSignalConnections() {
      let self = this
      return new Promise((resolve) => {
        setTimeout(() => {
          let webrtcCommonProps = {
            name: self.activeWebEngine.name,
            signalUrl: self.activeSignalServerUrl,
            room: self.activeSignalRoom,
            bandwidth: self.activeWebEngine.remote.bandwidth,
            maxBandwidth: self.activeWebEngine.remote.bandwidth,
            refComponent: self,
          }
          // @todo refactor later to setup selected janus
          if (self.useJanus) {
            webrtcCommonProps.multicastType = "janus"
            //webrtcCommonProps.multicastServer = 'https://j01-eu.arhtengine.com:443'
            webrtcCommonProps.multicastServer = ""
          }
          // initiate WebRTC sender
          let senderProps = {
            ...webrtcCommonProps,
            ...{
              turn: self.activeLocalTurnData,
              forceTurn: self.activeWebEngine.source.force_stop,
              maxAverageBitrate: self.activeWebEngine.source.maxAverageBitrate,
              bestturn: self.activeLocalTurnData?.data?.name,
            },
          }
          self.sender = new WebrtcSenderWebengine(senderProps)
          // initiate WebRTC receiver
          let receiverProps = {
            ...webrtcCommonProps,
            ...{
              turn: self.activeRemoteTurnData,
              forceTurn: self.activeWebEngine.remote.force_stop,
              bestturn: self.activeRemoteTurnData?.data?.name,
            },
          }
          self.receiver = new WebrtcReceiverWebengine(receiverProps)

          self.receiver.on("reconnect-preview", self.reconnectPreview)
          self.receiver.once("room-members", (roomMembers) => {
            self.updateSendersList(roomMembers.senders).then(() => {
              self.initSignalConnections().then(() => {
                resolve()
              })
            })
          })
          self.receiver.getAspxStreamsList()
          // resolve();
        }, 200)
      })
    },

    initSignalConnections() {
      let self = this
      return new Promise((resolve) => {
        if (self.activeWebEngine._key !== -1 && !self.initiatedSignalConns) {
          self.$store.dispatch("webenginesSetActiveSignal").then(() => {
            let prReceiver = self.receiver.getOrCreateConnection()
            let prSender = self.sender.getOrCreateConnection()
            Promise.all([prReceiver, prSender])
              .catch((errMsg) => {
                let params = {
                  text: "Error on connect signal server: " + errMsg,
                  show: true,
                  timeout: 6000,
                }
                self.$root.$emit("snackbar", params)
                resolve()
              })
              .then(() => {
                self.receiver.getAspxStreamsList()

                self.initiatedSignalConns = true
                resolve()

                // console.log('self.isWebrtcSourcesInitialised', self.isWebrtcSourcesInitialised)
                //               if (!self.isWebrtcSourcesInitialised) {
                //                 setTimeout(() => {
                //                   self.$store.dispatch('setDialogWebrtcSourcesStatus', true).then(() => {
                //                     // self.initiatedSignalConns = true;
                //                     // resolve();
                //                   })
                //                 }, 800)
                //
                //               } else {
                //                 self.initiatedSignalConns = true;
                //                 resolve();
                //               }

                // navigator.mediaDevices.enumerateDevices()
                //   .then(self.gotDevices).catch(self.handleGetUserMediaError).then(function () {
                //   //self.$store.dispatch('initLocalMediaConstraints', {app: app});
                //   self.initiatedSignalConns = true;
                //   resolve();
                // });
              })
          })
        } else {
          resolve()
        }
      })
    },

    gotDevices(deviceInfos) {
      console.log("deviceInfos", deviceInfos)
      return new Promise((resolve) => {
        this.$store.dispatch("saveRawWebrtcSources", deviceInfos).then(() => {
          this.$store.dispatch("saveWebrtcSources", deviceInfos).then(() => {
            resolve()
          })
        })
      })
    },

    initReceiverSignalConnection() {
      let self = this
      self.receiver.getOrCreateConnection().then(() => {
        self.receiver.getAspxStreamsList()
      })
    },

    // Update for webengine list of senders for show in dropdown for Receive stream block
    updateSendersList(newVal) {
      let self = this
      return new Promise((resolve) => {
        let sendersList = newVal
        let selectedStream = _.get(self.activeWebEngine, "remote.stream")
        if (selectedStream) {
          let findedIndex = _.findIndex(sendersList, {
            nickName: selectedStream.nickName,
          })
          if (findedIndex !== -1) {
            let findedStream = sendersList[findedIndex]
            if (selectedStream.id !== findedStream.id) {
              _.set(selectedStream, "id", findedStream.id)
              // update stream in database and store
              self.$store
                .dispatch("saveInputStream", selectedStream)
                .then(() => {
                  self.noValidationSave()
                })
            }
          } else {
            sendersList.push(selectedStream)
          }
        }
        self.$store
          .dispatch("saveWebengineSendersSignal", sendersList)
          .then(() => {
            resolve()
          })
      })
    },

    reconnectPreview(selectedStream) {
      this.startPreviewAspxStream(selectedStream, false)
    },

    // Run Local Source and Remote Streams
    runAllStreams() {
      let self = this
      return new Promise((resolve) => {
        if (self.editedIndex !== -1 || self.hasHashParam) {
          setTimeout(() => {
            let prmsCloseVideo = self.doStreamRestart
              ? self.sender.stopAllStreams()
              : Promise.resolve()
            prmsCloseVideo
              .then(() => {
                let localStreamPromise = self.noSourceToStart
                  ? Promise.resolve()
                  : self.startLocalStream()
                localStreamPromise
                  .then(() => {
                    let currentPlayedNickname = _.get(
                      self.activeWebEngine,
                      "remote.stream.nickname"
                    )
                    let startPreview =
                      !self.isPreviewRemoteStreamStarted ||
                      (self.isPreviewRemoteStreamStarted &&
                        self.sender.getNickName() === currentPlayedNickname)
                    // rerun preview only if we preview local stream (handle change source video/audio)
                    if (startPreview) {
                      setTimeout(() => {
                        self.startPreviewAspxStream()
                      }, 1000)
                    }
                    resolve()
                  })
                  .catch((error) => {
                    self.showSnackbarError({
                      consoleTitle:
                        "Error on runAllStreams--localStreamPromise",
                      error,
                    })
                    resolve()
                  })
              })
              .catch((error) => {
                self.showSnackbarError({
                  consoleTitle: "Error on runAllStreams--closeVideoCall",
                  error,
                })
                resolve()
              })
          }, 100)
        } else {
          resolve()
        }
      })
    },

    stopPreviewAspxStream() {
      let self = this
      return new Promise((resolve) => {
        if (self.receiver) {
          self.receiver.closeRemotesPreview().then(() => {
            resolve()
          })
        } else {
          resolve()
        }
      })
    },

    startLocalStream() {
      let self = this
      return new Promise((resolve) => {
        self.$validator.validateAll("form-webengine").then((result) => {
          if (result) {
            if (self.editedIndex === -1) {
              self.$refs.confirmSaveWebengine
                .open(
                  "Save webengine",
                  "Before start local stream you must save webengine",
                  { color: "red darken-4" }
                )
                .then((confirm) => {
                  if (confirm) {
                    self.save(false).then((key) => {
                      self.editedIndex = key
                      self.createSignalConnections().then(() => {
                        self
                          .initLocalStream()
                          .then(() => {
                            resolve()
                          })
                          .catch(() => {
                            resolve()
                          })
                      })
                    })
                  }
                })
            } else {
              self
                .initLocalStream()
                .then(() => {
                  resolve()
                })
                .catch(() => {
                  resolve()
                })
            }
          }
        })
      })
    },

    initLocalStream() {
      let self = this
      return new Promise((resolve, reject) => {
        self.$store.dispatch("saveLocalStreamErrors", { show: false })
        self.sender
          .getOrCreateConnection()
          .then(() => {
            setTimeout(function () {
              self.sender.refreshSignalInfo()
              self.sender
                .modeStartLocalStream(self.selectedVideoSource)
                .then(() => {
                  self.receiver.getAspxStreamsList()
                  resolve()
                })
                .catch((error) => {
                  self.stopLocalStream()
                  self.$store.dispatch("saveLocalStreamErrors", {
                    show: true,
                    text: error?.msg,
                  })
                  reject(error)
                })
            }, 200)
          })
          .catch(() => {
            self.$store.dispatch("saveLocalStreamErrors", {
              show: true,
              text: "Your stream cannot be started. Signal connection doesn't work.",
            })
          })
      })
    },

    showSnackbarError(props) {
      let self = this
      let defaultProps = {
        title: "Local stream cannot be started",
        error: "no error message",
        consoleTitle: "no console title",
      }
      let localProps = { ...defaultProps, ...props }
      let msgText = JSON.stringify(localProps.error)

      let params = {
        text: `${localProps.title}: ${msgText}`,
        show: true,
        timeout: 6000,
      }
      console.error(localProps.title, msgText)
      self.$root.$emit("snackbar", params)
    },

    stopLocalStream() {
      return this.sender ? this.sender.stopAllStreams() : Promise.resolve()
    },

    getRemoteStream(selectedStream) {
      let self = this
      return new Promise((resolve) => {
        let initialStream = selectedStream
          ? selectedStream
          : self.activeWebEngine.remote.stream
        self.sender
          .getOrCreateConnection(false)
          .then(() => {
            let streamToPlay = null
            let indexInListById = initialStream
              ? _.findIndex(self.signalRoomMembersSenders, {
                  id: initialStream.id,
                })
              : -1
            if (indexInListById !== -1) {
              streamToPlay = initialStream
            } else {
              let indexInListByNickname = initialStream
                ? _.findIndex(self.signalRoomMembersSenders, {
                    nickname: initialStream.nickname,
                  })
                : -1
              if (indexInListByNickname !== -1) {
                streamToPlay =
                  self.signalRoomMembersSenders[indexInListByNickname]
              }
            }
            resolve({ initial: initialStream, toPlay: streamToPlay })
          })
          .catch(() => {
            self.$store.dispatch("saveRemoteStreamErrors", {
              show: true,
              text: "Remote stream cannot be started. Signal connection doesn't work.",
            })
          })
      })
    },

    startPreviewAspxStream(selectedStream, showErrorSnackbar = true) {
      let self = this
      setTimeout(() => {
        // wait while roommembers are updated, for update remote stream ID if it was changes
        self.stopRemotePreviews().then(() => {
          self.receiver.getOrCreateConnection().then(() => {
            self.getRemoteStream(selectedStream).then((streamData) => {
              if (streamData.toPlay) {
                // we have something to play
                self.$store
                  .dispatch("saveInputStream", streamData.toPlay)
                  .then(() => {
                    self.receiver.makeOfferPreview(
                      streamData.toPlay,
                      self.sender
                    )
                  })
              } else if (showErrorSnackbar && streamData.initial) {
                //&& !self.noSourceToStart
                self.$store.dispatch("saveRemoteStreamErrors", {
                  show: true,
                  text: "Remote stream cannot be started.",
                })
                // if not finded stream to play we start reconnect
                self.receiver.previewedConn = streamData.initial
                self.receiver.reconnectPreview()
              }
            })
          })
        })
      }, 200)
    },

    stopRemotePreviews() {
      let self = this
      return new Promise((resolve) => {
        self.receiver.closeRemotesPreview().then(() => {
          self.receiver.disconnect().then(() => {
            resolve()
          })
        })
      })
    },

    onDestroyPage() {
      let self = this
      if (self.sender && self.receiver) {
        self.sender.stopAllStreams().then(() => {
          self.receiver.closeRemotesPreview().then(() => {
            self.sender.disconnect()
            self.receiver.disconnect()
          })
        })
      }
    },

    noValidationSave() {
      let self = this
      return new Promise((resolve) => {
        let formData = {
          slug: this.editedIndex,
          data: {
            name: self.activeWebEngine.name,
            signal_server_key: self.activeWebEngine.signal_server_key,
            common_room: self.activeWebEngine.common_room,
            is_active: self.activeWebEngine.is_active,
            source: {
              video_source: self.activeWebEngine.source.video_source,
              audio_source: self.activeWebEngine.source.audio_source,
              // min_framerate: parseInt(self.activeWebEngine.source.min_framerate),
              // max_framerate: parseInt(self.activeWebEngine.source.max_framerate),
              turn_server_key: self.activeWebEngine.source.turn_server_key,
              force_stop: self.activeWebEngine.source.force_stop,
              stream_type: self.activeWebEngine.source.stream_type,
              resolution: self.activeWebEngine.source.resolution,
              video_codec: self.activeWebEngine.source.video_codec,
            },
            remote: {
              stream: self.activeWebEngine.remote.stream
                ? _.omit(self.activeWebEngine.remote.stream, [
                    "token",
                    "loginHash",
                  ])
                : null,
              turn_server_key: self.activeWebEngine.remote.turn_server_key,
              force_stop: self.activeWebEngine.remote.force_stop,
              bandwidth: self.activeWebEngine.remote.bandwidth,
            },
          },
        }
        if (!self.hasHashParam) {
          self.$store.dispatch("webenginesEditAction", formData).then(() => {
            resolve()
          })
        }
      })
    },
    generateLink(key) {
      let self = this
      self.generateLinkLoading = true
      setTimeout(() => {
        self.$store
          .dispatch("webenginesGenerateLinkAction", { web_engine_key: key })
          .then((response) => {
            self.$store.commit("SET_GUEST_LINK", response.data.data.link_hash)
            setTimeout(() => {
              self.selectLinkText()
              self.generateLinkLoading = false
            }, 20)
          })
          .catch((error) => {
            self.handleValidationErrors(
              error.response,
              "form-speed-test-generate-link"
            )
          })
      }, 3000)
    },
    selectLinkText() {
      const input = document.getElementById("web-engine-generated-link")
      input.select()
    },
    copyToClipboard() {
      let self = this
      self.selectLinkText()
      navigator.clipboard.writeText(self.getGuestLink).then(
        function () {
          let params = {
            text: "Webengine link was copied successful!",
            show: true,
          }
          self.$root.$emit("snackbar", params)
        },
        function () {
          let params = {
            text: "Webengine link was copied without success!",
            show: true,
          }
          self.$root.$emit("snackbar", params)
        }
      )
    },
  },
  computed: {
    ...mapGetters({
      activeWebEngine: "getActiveWebEngine",
      modelWebengine: "getModelWebengine",
      roomMembers: "getRoomMembersWsConn",
      activeSignalRoom: "getActiveSignalRoom",
      activeSignalServerUrl: "getActiveSignalServerUrl",
      activeLocalTurnData: "getActiveLocalTurnData",
      activeRemoteTurnData: "getActiveRemoteTurnData",
      signalRoomMembersSenders: "signalRoomMembersSenders",
      doStreamRestart: "doStreamRestart",
      isWebrtcSourcesInitialised: "isWebrtcSourcesInitialised",
    }),

    hasHashParam() {
      return !!this.link_hash
    },

    useJanus: {
      get() {
        return this.$store.getters.useJanus
      },
      set(val) {
        this.$store.dispatch("saveUseJanus", val)
      },
    },
  },
}
