var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-testid":"container-webengines"}},[_c('v-card',[_c('v-toolbar',[_c('v-card-title',[_vm._v("Web Engines")]),_c('v-spacer'),(_vm.$can('create', 'web_engines') || _vm.$can('create', 'Superadmin'))?_c('v-btn',{attrs:{"color":_vm.Pallette.actionButtons.newItem,"fab":"","dark":"","absolute":"","bottom":"","right":"","small":!_vm.$vuetify.breakpoint.smAndUp,"to":`/engines/webengine/create`,"data-testid":"btn-add-webengine"}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('v-card-text',[_c('DataTableExtended',{ref:"table",staticClass:"elevation-0 border",attrs:{"calculate-widths":true,"headers":_vm.headers,"items":_vm.webengines,"server-items-length":_vm.webenginesCount,"loading":_vm.showTableLoader,"no-data-text":_vm.showTableLoader ? 'Loading…' : 'No data available',"customSearch":_vm.filterSearch,"data-testid":"table-webengines"},on:{"init-table-data":_vm.fetchTableData,"update-search":(newS) => {
            this.filterSearch = newS
          }},scopedSlots:_vm._u([{key:"top",fn:function({ options, updateOptions }){return [_c('v-row',{staticClass:"pa-3"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{style:(_vm.$vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''),attrs:{"outlined":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":"","data-testid":"filter-search"},model:{value:(_vm.filterSearch),callback:function ($$v) {_vm.filterSearch=$$v},expression:"filterSearch"}})],1)],1),(!_vm.$vuetify.breakpoint.smAndUp)?_c('SortMobile',{attrs:{"headers":_vm.headers,"options":options},on:{"update:options":updateOptions}}):_vm._e()]}},(_vm.$vuetify.breakpoint.smAndUp)?{key:`item.play`,fn:function({ item }){return [_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"fab":"","dark":"","x-small":"","color":_vm.Pallette.actionButtons.play,"to":`/engines/webengine/streaming/${item._key}`,"data-testid":"btn-start-streaming"}},on),[_c('v-icon',[_vm._v("mdi-play")])],1)]}}],null,true)},[_c('span',[_vm._v("Start streaming")])])],1)]}}:null,{key:`item.name`,fn:function({ item }){return [_c('span',{attrs:{"data-testid":"webengine-name"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:`item.is_active`,fn:function({ item }){return [(
              _vm.$can('update', 'web_engines') || _vm.$can('update', 'Superadmin')
            )?_c('v-chip',{staticClass:"chip-status-webengine",attrs:{"small":"","color":item.is_active
                ? _vm.Pallette.statusChips.active
                : _vm.Pallette.statusChips.notActive,"text-color":"white","data-testid":"webengine-status"},on:{"click":function($event){return _vm.updateWebengineStatus(item)}}},[_vm._v(" "+_vm._s(item.is_active ? "Active" : "Inactive")+" ")]):_c('v-chip',{staticClass:"chip-status-webengine",attrs:{"small":"","color":item.is_active
                ? _vm.Pallette.statusChips.active
                : _vm.Pallette.statusChips.notActive,"text-color":"white","data-testid":"webengine-status"}},[_vm._v(" "+_vm._s(item.is_active ? "Active" : "Inactive")+" ")])]}},{key:`item.menu`,fn:function({ item }){return [(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"text-center d-flex align-center justify-space-around"},[(
                _vm.$can('update', 'web_engines') || _vm.$can('update', 'Superadmin')
              )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"fab":"","dark":"","x-small":"","color":_vm.Pallette.actionButtons.edit,"to":`/engines/webengine/edit/${item._key}`,"data-testid":"btn-edit-webengine"}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit webengine")])]):_vm._e(),(
                _vm.$can('delete', 'web_engines') || _vm.$can('delete', 'Superadmin')
              )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"fab":"","dark":"","x-small":"","color":_vm.Pallette.actionButtons.delete,"data-testid":"btn-delete-webengine"},on:{"click":function($event){return _vm.deleteWebEngine(item)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete webengine")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"fab":"","dark":"","x-small":"","color":_vm.Pallette.actionButtons.generate,"data-testid":"btn-generate-link-webengine"},on:{"click":function($event){return _vm.getGeneratedLink(item)}}},on),[_c('v-icon',[_vm._v("mdi-link-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Link")])])],1):_vm._e(),(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-menu',{attrs:{"transition":"slide-y-transition","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"clear-padding",attrs:{"fab":"","dark":"","x-small":"","outlined":"","color":_vm.Pallette.actionButtons.menu,"data-testid":"btn-webengine-actions-more"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(
                  _vm.$can('update', 'web_engines') ||
                  _vm.$can('update', 'Superadmin')
                )?_c('v-list-item',{attrs:{"to":`/engines/webengine/edit/${item._key}`,"data-testid":"btn-edit-webengine"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Edit webengine")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.Pallette.actionButtons.edit}},[_vm._v("mdi-pencil ")])],1)],1):_vm._e(),(
                  _vm.$can('delete', 'web_engines') ||
                  _vm.$can('delete', 'Superadmin')
                )?_c('v-list-item',{attrs:{"data-testid":"btn-delete-webengine"},on:{"click":function($event){return _vm.deleteWebEngine(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Delete webengine")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.Pallette.actionButtons.delete}},[_vm._v(" mdi-delete ")])],1)],1):_vm._e(),_c('v-list-item',{attrs:{"data-testid":"btn-generate-link-webengine"},on:{"click":function($event){return _vm.getGeneratedLink(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Link")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.Pallette.actionButtons.generate}},[_vm._v("mdi-link-variant ")])],1)],1)],1)],1):_vm._e()]}},(
            !_vm.$vuetify.breakpoint.smAndUp &&
            (_vm.$can('delete', 'web_engines') ||
              _vm.$can('update', 'web_engines') ||
              _vm.$can('update', 'Superadmin'))
          )?{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('VerticalButton',{attrs:{"route":`/engines/webengine/streaming/${item._key}`,"icon":"mdi-play","text":"Start streaming","color":_vm.Pallette.actionButtons.play,"data-testid":"btn-start-streaming"}}),(
                  _vm.$can('update', 'web_engines') ||
                  _vm.$can('update', 'Superadmin')
                )?_c('VerticalButton',{attrs:{"route":`/engines/webengine/edit/${item._key}`,"icon":"mdi-pencil","text":"Edit webengine","color":_vm.Pallette.actionButtons.edit,"data-testid":"btn-edit-webengine"}}):_vm._e(),(
                  _vm.$can('delete', 'web_engines') ||
                  _vm.$can('delete', 'Superadmin')
                )?_c('VerticalButton',{attrs:{"icon":"mdi-delete","text":"Delete webengine","color":_vm.Pallette.actionButtons.delete,"data-testid":"btn-delete-webengine"},nativeOn:{"click":function($event){return _vm.deleteWebEngine(item)}}}):_vm._e()],1)],1)]}}:null],null,true)})],1)],1),_c('v-dialog',{attrs:{"persistent":"","no-click-animation":"","max-width":"600px"},model:{value:(_vm.dialogLink),callback:function ($$v) {_vm.dialogLink=$$v},expression:"dialogLink"}},[_c('v-card',{attrs:{"tile":"","data-testid":"container-generate-webengine-link"}},[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.webEngine),expression:"webEngine"}]},[_c('span',{staticClass:"blue-grey--text text--lighten-4"},[_vm._v("Generate link for Web Engine:")]),_vm._v(" "+_vm._s(_vm.webEngine.name)+" ")])]),_c('v-spacer')],1),_c('v-card-text',[_c('v-form',{staticClass:"mt-5",attrs:{"data-testid":"form-generate-webengine-link"}},[_c('v-text-field',{attrs:{"label":"Guest link","outlined":"","single-line":"","readonly":"","dense":"","id":"web-engine-generated-link","messages":!_vm.canGenerateEngineLinkComputed
                ? 'Old link will be disabled'
                : 'To generate link need to save this engine ',"disabled":_vm.canGenerateEngineLinkComputed || _vm.generateLinkLoading,"data-testid":"guest-link"},scopedSlots:_vm._u([(!_vm.canGenerateEngineLinkComputed)?{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',{staticStyle:{"height":"28px","width":"28px"},attrs:{"icon":"","disabled":_vm.generateLinkLoading,"data-testid":"btn-copy-guest-link"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.copyToClipboard.apply(null, arguments)}}},[_c('v-icon',_vm._g({},on),[_vm._v("mdi-content-copy")])],1)]}}],null,false,1791862458)},[_vm._v(" Copy to clipboard ")])]},proxy:true}:null],null,true),model:{value:(_vm.getGuestLink),callback:function ($$v) {_vm.getGuestLink=$$v},expression:"getGuestLink"}})],1)],1),_c('v-card-actions',{staticClass:"pb-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"success","loading":_vm.generateLinkLoading,"data-testid":"btn-generate-guest-link"},on:{"click":function($event){return _vm.generateLink(_vm.webEngine._key)}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',{attrs:{"data-testid":"loading-generate-guest-link"}},[_vm._v("Loading...")])]},proxy:true}])},[_vm._v(" Generate link ")]),_c('v-btn',{attrs:{"color":"error","text":"","data-testid":"btn-close"},on:{"click":function($event){_vm.dialogLink = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('Confirm',{ref:"confirmDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }