<template>
  <div data-testid="container-webengines">
    <v-card>
      <v-toolbar>
        <v-card-title>Web Engines</v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="$can('create', 'web_engines') || $can('create', 'Superadmin')"
          :color="Pallette.actionButtons.newItem"
          fab
          dark
          absolute
          bottom
          right
          :small="!$vuetify.breakpoint.smAndUp"
          :to="`/engines/webengine/create`"
          data-testid="btn-add-webengine"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <DataTableExtended
          ref="table"
          :calculate-widths="true"
          :headers="headers"
          :items="webengines"
          :server-items-length="webenginesCount"
          class="elevation-0 border"
          :loading="showTableLoader"
          :no-data-text="showTableLoader ? 'Loading…' : 'No data available'"
          v-on:init-table-data="fetchTableData"
          :customSearch="filterSearch"
          @update-search="
            (newS) => {
              this.filterSearch = newS
            }
          "
          data-testid="table-webengines"
        >
          <template v-slot:top="{ options, updateOptions }">
            <v-row class="pa-3">
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  v-model="filterSearch"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  :style="$vuetify.breakpoint.smAndUp ? 'max-width: 300px' : ''"
                  clearable
                  data-testid="filter-search"
                />
              </v-col>
            </v-row>
            <SortMobile
              :headers="headers"
              :options="options"
              @update:options="updateOptions"
              v-if="!$vuetify.breakpoint.smAndUp"
            />
          </template>
          <template
            v-slot:[`item.play`]="{ item }"
            v-if="$vuetify.breakpoint.smAndUp"
          >
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    dark
                    x-small
                    :color="Pallette.actionButtons.play"
                    v-on="on"
                    :to="`/engines/webengine/streaming/${item._key}`"
                    data-testid="btn-start-streaming"
                  >
                    <v-icon>mdi-play</v-icon>
                  </v-btn>
                </template>
                <span>Start streaming</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <span data-testid="webengine-name">
              {{ item.name }}
            </span>
          </template>
          <template v-slot:[`item.is_active`]="{ item }">
            <v-chip
              v-if="
                $can('update', 'web_engines') || $can('update', 'Superadmin')
              "
              @click="updateWebengineStatus(item)"
              small
              :color="
                item.is_active
                  ? Pallette.statusChips.active
                  : Pallette.statusChips.notActive
              "
              text-color="white"
              class="chip-status-webengine"
              data-testid="webengine-status"
            >
              {{ item.is_active ? "Active" : "Inactive" }}
            </v-chip>
            <v-chip
              v-else
              small
              :color="
                item.is_active
                  ? Pallette.statusChips.active
                  : Pallette.statusChips.notActive
              "
              text-color="white"
              class="chip-status-webengine"
              data-testid="webengine-status"
            >
              {{ item.is_active ? "Active" : "Inactive" }}
            </v-chip>
          </template>
          <template v-slot:[`item.menu`]="{ item }">
            <div
              class="text-center d-flex align-center justify-space-around"
              v-if="$vuetify.breakpoint.mdAndUp"
            >
              <v-tooltip
                top
                v-if="
                  $can('update', 'web_engines') || $can('update', 'Superadmin')
                "
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    dark
                    x-small
                    :color="Pallette.actionButtons.edit"
                    v-on="on"
                    :to="`/engines/webengine/edit/${item._key}`"
                    class="mx-1"
                    data-testid="btn-edit-webengine"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit webengine</span>
              </v-tooltip>

              <v-tooltip
                top
                v-if="
                  $can('delete', 'web_engines') || $can('delete', 'Superadmin')
                "
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    dark
                    x-small
                    :color="Pallette.actionButtons.delete"
                    v-on="on"
                    @click="deleteWebEngine(item)"
                    class="mx-1"
                    data-testid="btn-delete-webengine"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete webengine</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    dark
                    x-small
                    :color="Pallette.actionButtons.generate"
                    v-on="on"
                    @click="getGeneratedLink(item)"
                    class="mx-1"
                    data-testid="btn-generate-link-webengine"
                  >
                    <v-icon>mdi-link-variant</v-icon>
                  </v-btn>
                </template>
                <span>Link</span>
              </v-tooltip>
            </div>

            <v-menu
              transition="slide-y-transition"
              left
              v-if="!$vuetify.breakpoint.mdAndUp"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  fab
                  dark
                  x-small
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :color="Pallette.actionButtons.menu"
                  class="clear-padding"
                  data-testid="btn-webengine-actions-more"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  :to="`/engines/webengine/edit/${item._key}`"
                  v-if="
                    $can('update', 'web_engines') ||
                    $can('update', 'Superadmin')
                  "
                  data-testid="btn-edit-webengine"
                >
                  <v-list-item-content>
                    <v-list-item-title>Edit webengine</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.edit"
                      >mdi-pencil
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>

                <v-list-item
                  @click="deleteWebEngine(item)"
                  v-if="
                    $can('delete', 'web_engines') ||
                    $can('delete', 'Superadmin')
                  "
                  data-testid="btn-delete-webengine"
                >
                  <v-list-item-content>
                    <v-list-item-title>Delete webengine</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.delete">
                      mdi-delete
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>

                <v-list-item
                  @click="getGeneratedLink(item)"
                  data-testid="btn-generate-link-webengine"
                >
                  <v-list-item-content>
                    <v-list-item-title>Link</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon :color="Pallette.actionButtons.generate"
                      >mdi-link-variant
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template
            v-slot:expanded-item="{ headers, item }"
            v-if="
              !$vuetify.breakpoint.smAndUp &&
              ($can('delete', 'web_engines') ||
                $can('update', 'web_engines') ||
                $can('update', 'Superadmin'))
            "
          >
            <td :colspan="headers.length">
              <v-row align="center" justify="space-around">
                <VerticalButton
                  :route="`/engines/webengine/streaming/${item._key}`"
                  icon="mdi-play"
                  text="Start streaming"
                  :color="Pallette.actionButtons.play"
                  data-testid="btn-start-streaming"
                />
                <VerticalButton
                  :route="`/engines/webengine/edit/${item._key}`"
                  v-if="
                    $can('update', 'web_engines') ||
                    $can('update', 'Superadmin')
                  "
                  icon="mdi-pencil"
                  text="Edit webengine"
                  :color="Pallette.actionButtons.edit"
                  data-testid="btn-edit-webengine"
                />
                <VerticalButton
                  @click.native="deleteWebEngine(item)"
                  v-if="
                    $can('delete', 'web_engines') ||
                    $can('delete', 'Superadmin')
                  "
                  icon="mdi-delete"
                  text="Delete webengine"
                  :color="Pallette.actionButtons.delete"
                  data-testid="btn-delete-webengine"
                />
              </v-row>
            </td>
          </template>
        </DataTableExtended>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialogLink"
      persistent
      no-click-animation
      max-width="600px"
    >
      <v-card tile data-testid="container-generate-webengine-link">
        <v-toolbar dense flat>
          <v-toolbar-title class="white--text">
            <div v-show="webEngine">
              <span class="blue-grey--text text--lighten-4"
                >Generate link for Web Engine:</span
              >
              {{ webEngine.name }}
            </div>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form class="mt-5" data-testid="form-generate-webengine-link">
            <v-text-field
              v-model="getGuestLink"
              label="Guest link"
              outlined
              single-line
              readonly
              dense
              id="web-engine-generated-link"
              :messages="
                !canGenerateEngineLinkComputed
                  ? 'Old link will be disabled'
                  : 'To generate link need to save this engine '
              "
              :disabled="canGenerateEngineLinkComputed || generateLinkLoading"
              data-testid="guest-link"
            >
              <template
                v-slot:append-outer
                v-if="!canGenerateEngineLinkComputed"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      @click.stop.prevent="copyToClipboard"
                      :disabled="generateLinkLoading"
                      style="height: 28px; width: 28px"
                      data-testid="btn-copy-guest-link"
                    >
                      <v-icon v-on="on">mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  Copy to clipboard
                </v-tooltip>
              </template>
            </v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            @click="generateLink(webEngine._key)"
            :loading="generateLinkLoading"
            data-testid="btn-generate-guest-link"
          >
            Generate link
            <template v-slot:loader>
              <span data-testid="loading-generate-guest-link">Loading...</span>
            </template>
          </v-btn>
          <v-btn
            color="error"
            text
            @click="dialogLink = false"
            data-testid="btn-close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Confirm ref="confirmDialog"></Confirm>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Confirm from "@/components/Confirm"
import BackButtonMixin from "@/mixins/close-modal-on-backbutton.js"
import WECommonMixin from "@/mixins/webengine/common"
import VerticalButton from "@/components/buttons/VerticalButton"
import DataTableExtended from "@/components/table/DataTableExtended"
import SortMobile from "@/components/table/SortMobile"

export default {
  components: {
    Confirm,
    VerticalButton,
    DataTableExtended,
    SortMobile,
  },

  mixins: [BackButtonMixin, WECommonMixin],

  mounted: function () {
    if (!this.$can("read", "web_engines")) {
      if (!this.$can("read", "Superadmin"))
        this.$router.push({ name: "home" }).catch((err) => {})
    }
    this.calculateMenuColumnWidth()
  },

  data() {
    return {
      headers: [
        {
          text: "",
          value: "play",
          sortable: false,
          width: 40,
        },
        {
          text: "Name",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "Status",
          align: "center",
          value: "is_active",
          sortable: true,
          width: 120,
        },
        {
          text: "",
          value: "menu",
          align: "center",
          sortable: false,
          class: "th-clear-padding",
          width: "1%",
        },
      ],
      webengines: [],
      webenginesCount: 0,
      dialogLink: false,
      webEngine: {},
      filterSearch: "",
    }
  },
  watch: {
    filterSearch() {
      this.fetchTableData()
    },
  },
  methods: {
    changedEditedIndex(val) {
      this.editedIndex = val
    },
    fetchTableData: function () {
      let self = this
      let getParams = self.$refs.table.getTableServerParams()
      if (self.filterSearch !== "") getParams.search = self.filterSearch
      self.$store.dispatch("saveTableLoaderAction", true)
      self.$store
        .dispatch("webenginesGetList", { params: getParams })
        .then(function (response) {
          self.webengines = response?.data?.data?.web_engines?.result || []
          self.webenginesCount =
            response?.data?.data?.web_engines?.full_count || 0
          self.$store.dispatch("saveTableLoaderAction", false)
        })
    },
    deleteWebEngine(item) {
      let self = this
      self.$refs.confirmDialog
        .open("Delete", "Are you sure you want to delete this Web Engine?", {
          color: "red darken-4",
        })
        .then((confirm) => {
          if (confirm) {
            self.$store.dispatch("saveTableLoaderAction", true)
            self.$store
              .dispatch("webenginesDeleteAction", item)
              .then(({ data }) => {
                self.fetchTableData()
                self.$store.dispatch("saveTableLoaderAction", false)
              })
          }
        })
    },
    updateWebengineStatus(item) {
      let self = this
      let statusToChange = !item.is_active
      let data = {
        slug: item._key,
        data: {
          name: item.name,
          signal_server_key: item.signal_server_key,
          is_active: statusToChange,
        },
      }
      self.$store
        .dispatch("webenginesEditAction", data)
        .then(({ data }) => {
          let params = {
            text: `Web Engine ${item.name} status was edited successfully to ${
              statusToChange ? "Active" : "Inactive"
            }.`,
            show: true,
          }
          self.$root.$emit("snackbar", params)
          self.fetchTableData()
        })
        .catch((error) => {
          console.log("error on editWebEngine", error.response)
        })
    },
    getGeneratedLink(item) {
      let self = this
      self.webEngine = item
      self.$store
        .dispatch("webengineGetLink", { web_engine_key: self.webEngine._key })
        .then((response) => {
          self.$store.commit("SET_GUEST_LINK", response.data.data.link_hash)
          self.dialogLink = true
          setTimeout(() => {
            self.selectLinkText()
          }, 20)
        })
        .catch((error) => {
          self.handleValidationErrors(
            error.response,
            "form-speed-test-generate-link"
          )
        })
    },
    calculateMenuColumnWidth() {
      // let menuHeader = this.headers.find(itm => itm.value === 'menu')
      // if (menuHeader) {
      //   let width = 50
      //   if (this.$vuetify.breakpoint.mdAndUp) {
      //     if (this.$can('update', 'web_engines') || this.$can('update', 'Superadmin')) {
      //       width = width + 50
      //     }
      //     if (this.$can('delete', 'web_engines') || this.$can('delete', 'Superadmin')) {
      //       width = width + 50
      //     }
      //
      //   }
      //   menuHeader.width = width
      // }
    },
  },
  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      Pallette: "get_Pallette",
      getGuestLink: "getGuestLink",
    }),
    canGenerateEngineLinkComputed() {
      if (this.webEngine.name !== "" && this.editedIndex !== -1) return false
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.chip-status-webengine {
  .v-chip__content {
    cursor: pointer;
  }
}
</style>
